import React, { useEffect, useState } from "react";
import Select from "react-select";

const SelectFieldShop = ({ defaultMenuIsOpen, selected, fieldName, options, onSelect }) => {
  const [value, setValue] = useState(selected);

    useEffect(() => {
        onSelect(value)
    }, [value, onSelect])

  const customStyles = () => {
    return {
      option: function (base, state) {
        return {
          ...base,
          padding: 8,
        };
      },
      control: function (base, state) {
        return {
          ...base,
          padding: "8px 8px 8px 10px",
          borderRadius: 0,
          border: "1px solid #9a9a9a",
          background: "#ffffff",
        };
      },
      multiValueLabel: function (base, state) {
        return {
          ...base,
          padding: "10px 8px 8px 10px",
          paddingLeft: 10,
          fontSize: "1em",
        };
      },
      multiValueRemove: function (base, state) {
        return {
          ...base,
          paddingRight: 8,
          paddingLeft: 8,
          ":hover": {
            color: "#721c24",
            backgroundColor: "#F9D7DD",
          },
        };
      },
      valueContainer: function (base, state) {
        return {
          ...base,
          padding: 0,
        };
      },
    };
  };

  const handleChange = (selection) => {
    setValue(selection);
  };

  return (
    <Select
      defaultMenuIsOpen={defaultMenuIsOpen}
      name={fieldName}
      styles={customStyles()}
      options={options}
      closeMenuOnSelect
      placeholder="Please Select"
      defaultValue={value}
      onChange={handleChange}
    />
  );
};

export default SelectFieldShop;

// Core
// 
import { Controller } from 'stimulus';

// Helpers
// 
import { addClass, removeClass, hasClass } from '../../../dom-helper';

// Plugins
// 
import axios, { AxiosResponse } from 'axios';

// Components
// 
import PubSub from 'pubsub-js';

export default class extends Controller {
  static targets = ['bulkEditAction', 'bulkEditProjectAction', 'newTaskAction', 'bulkEditShopActionTarget'];

  bulkEditActionTarget: any;
  bulkEditProjectActionTarget: any;
  newTaskActionTarget: any;
  bulkEditShopActionTarget: any;

  window: any = window;

  topic: string = 'item.selector';
  token: any;

  items: Array<any> = [];

  connect() {
    this.token = PubSub.subscribe(this.topic, (message: any, item: any) => {
      if (item.selected) {
        this.items.push(item)
      } else {
        const checkedItem: any = item;
        const itemIndex: number = this.items.map((item: any) => { return item.uuid }).indexOf(checkedItem.uuid);

        this.items.splice(itemIndex, 1);
      }
      console.log(this.items)
      // const counter: any = document.getElementById('itemsCounter');
      //       counter.innerHTML = this.items.length;

      // const container: any = document.getElementById('itemsSelectionContainer');

      if (this.items.length > 0 ) {
        removeClass(this.bulkEditActionTarget, '-color-gray_dark');
        this.bulkEditActionTarget.setAttribute('title', '');
        this.bulkEditActionTarget.setAttribute('data-original-title', '');

        removeClass(this.bulkEditProjectActionTarget, '-color-gray_dark');
        this.bulkEditProjectActionTarget.setAttribute('title', '');
        this.bulkEditProjectActionTarget.setAttribute('data-original-title', '');

        removeClass(this.newTaskActionTarget, '-color-gray_dark');
        this.newTaskActionTarget.setAttribute('title', '');
        this.newTaskActionTarget.setAttribute('data-original-title', '');

        removeClass(this.bulkEditShopActionTarget, '-color-gray_dark');
        this.bulkEditShopActionTarget.setAttribute('title', '');
        this.bulkEditShopActionTarget.setAttribute('data-original-title', '');
      } else {
        addClass(this.bulkEditActionTarget, '-color-gray_dark');
        this.bulkEditActionTarget.setAttribute('title', 'You must select at least 1 Asset to Bulk Edit');

        addClass(this.bulkEditProjectActionTarget, '-color-gray_dark');
        this.bulkEditProjectActionTarget.setAttribute('title', 'You must select at least 1 Asset to Bulk Edit Project');

        addClass(this.newTaskActionTarget, '-color-gray_dark');
        this.newTaskActionTarget.setAttribute('title', 'You must select at least 1 Asset to create a new Work Request');

        addClass(this.bulkEditShopActionTarget, '-color-gray_dark');
        this.bulkEditShopActionTarget.setAttribute('title', 'You must select at least 1 Asset to Transfer to Shop');
      }
    })
  }

  bulkEditItems(event: any) {
    event.preventDefault();

    const url: string = this.bulkEditActionTarget.dataset.url;
    this.window.location = `${ url }?items=${ this.items.map(item => item.uuid) }`;
  }

  bulkEditProjectItems(event: any) {
    event.preventDefault();

    const url: string = this.bulkEditProjectActionTarget.dataset.url;
    this.window.location = `${ url }?project_only=true&items=${ this.items.map(item => item.uuid) }`;
  }

  bulkEditShopItems(event: any) {
    event.preventDefault();

    const url: string = this.bulkEditProjectActionTarget.dataset.url;
    this.window.location = `${ url }?add_to_shop=true&items=${ this.items.map(item => item.uuid) }`;
  }

  disconnect() {
    PubSub.unsubscribe(this.token);
  }  
}
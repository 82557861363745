import React, { useCallback, useEffect, useMemo, useState } from "react";
import SelectFieldShop from "./SelectFieldShop";

const MainComponent = ({ taxonomy }) => {
  const [selectedTopLevel, setSelectedTopLevel] = useState();
  const [selectedSecondLevel, setSelectedSecondLevel] = useState();
  const [selectedThirdLevel, setSelectedThirdLevel] = useState();

  useEffect(() => {
    if (Object.values(taxonomy?.taxons).length === 1) {
      setSelectedTopLevel(taxonomy?.taxons[0]);
    }
  }, [taxonomy]);

  const topLevel = useMemo(() => {
    return Object.values(taxonomy?.taxons).filter(taxon => taxon.parent_id === null).map((taxon) => ({
      value: taxon.id,
      label: taxon.name,
    })).sort((a,b) => a.name > b.name ? -1 : 1);
  }, [taxonomy]);

  const secondLevel = useMemo(() => {
    return !selectedTopLevel
      ? []
      : Object.values(taxonomy?.taxons)
          .find((taxon) => taxon.id === selectedTopLevel)
          .taxons.map((taxon) => ({ value: taxon.id, label: taxon.name }))
          .sort((a,b) => a.name > b.name ? -1 : 1);
  }, [taxonomy, selectedTopLevel]);

  const thirdLevel = useMemo(() => {
    return !selectedTopLevel || !selectedSecondLevel
      ? []
      : Object.values(taxonomy?.taxons)
          .find((taxon) => taxon.id === selectedTopLevel)
          .taxons.find((taxon) => taxon.id === selectedSecondLevel)
          .taxons.map((taxon) => ({ value: taxon.id, label: taxon.name }))
          .sort((a,b) => a.name > b.name ? -1 : 1);
  }, [taxonomy, selectedTopLevel, selectedSecondLevel]);

  const isMenuOpen = (index) => {
    if( index === 1 ){
        return !selectedTopLevel
    }
    if( index === 2 ){
        return selectedTopLevel && !selectedSecondLevel
    }
    if( index === 3 ){
        return selectedSecondLevel && !selectedThirdLevel
    }
  }

  const handleTopLevelChange = (selected) => {
    setSelectedTopLevel(selected?.value);
  };

  const handleSecondLevelChange = (selected) => {
    setSelectedSecondLevel(selected?.value);
  };
  const handleThirdLevelChange = (selected) => {
    setSelectedThirdLevel(selected?.value);
  };

  return (
    <div>
      <fieldset>
        <legend>'Shop Catalogue'</legend>
        <SelectFieldShop
          defaultMenuIsOpen={isMenuOpen(1)}x
          selected={selectedTopLevel}
          fieldName={"shop_catalogue"}
          options={topLevel}
          onSelect={handleTopLevelChange}
        />
      </fieldset>
      
      <fieldset>
        <legend>'Shop Category'</legend>
        <SelectFieldShop
          defaultMenuIsOpen={isMenuOpen(2)}
          selected={selectedSecondLevel}
          fieldName={"shop_category"}
          options={secondLevel}
          onSelect={handleSecondLevelChange}
        />
      </fieldset>
      
      <fieldset>
        <legend>'Shop Type'</legend>
        <SelectFieldShop
          defaultMenuIsOpen={isMenuOpen(2)}
          selected={selectedThirdLevel}
          fieldName={"shop_type"}
          options={thirdLevel}
          onSelect={handleThirdLevelChange}
        />
      </fieldset>
    </div>
  );
};

export default MainComponent;
